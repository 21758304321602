import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import Header from 'components/profile/Header';
import Content from 'components/profile/Content';

export default function Profile() {
    return (
        <>
            {/* <div className="absolute w-full z-20">
                <DefaultNavbar />
            </div> */}
            <main>
                {/* <Header /> */}
                <Content />
            </main>
            <a href="https://www.flaticon.com/free-icons/user" title="user icons">User icons created by Smashicons - Flaticon</a>
            {/* <DefaultFooter /> */}
        </>
    );
}
