import Button from '@material-tailwind/react/Button';
import Image from '@material-tailwind/react/Image';
import H3 from '@material-tailwind/react/Heading3';
import Icon from '@material-tailwind/react/Icon';
import LeadText from '@material-tailwind/react/LeadText';
import ProfilePicture from 'assets/img/user.png';
import { EditText } from 'react-edit-text';
import TagsInput from 'react-tagsinput';
import { useRef, useState } from 'react';
import 'react-tagsinput/react-tagsinput.css'
import 'assets/styles/custom.css'
import Avatar from 'react-avatar-edit';
// import { Modal } from '@material-tailwind/react';
import Modal from 'react-modal';
import ReactToPrint from 'react-to-print';
const Education = ({ index, data, addNewQualification, removeNewQualification }) => {
    const [showAdd, setShowAdd] = useState(false)
    return (
        <div className='px-5 py-2 hover:bg-gray-100 relative' onMouseOver={() => setShowAdd(true)} onMouseOut={() => setShowAdd(false)}>
            <table>
                <tr><td>
                    <EditText
                        defaultValue={data.course}
                        inputClassName='text-xl '
                        className='text-xl font-semibold'
                    />
                </td></tr>
                <tr><td>
                    <EditText
                        defaultValue={data.university}
                        inputClassName='text-xl '
                        className='text-xl text-gray-600 '
                    />
                </td></tr>
                <tr><td>
                    <EditText
                        defaultValue={data.duration}
                        inputClassName='text-md '
                        className='text-md text-gray-600  italic'
                    />
                </td></tr>
            </table>
            <div className={`flex justify-end absolute right-0 top-2 ${showAdd ? `visible` : 'invisible'}`} >
                <div className={' hover:cursor-pointer px-5'} onClick={addNewQualification}><Icon name="add" size="3xl" /></div>
                <div className={' hover:cursor-pointer px-5'} onClick={() => removeNewQualification(index)}><Icon name="delete" size="3xl" /></div>
            </div>
        </div>
    )
}
const Languages = ({ index, data, addNewLanguages, removeNewLanguages }) => {
    const [showAdd, setShowAdd] = useState(false)

    return (
        <div className='px-5 py-2 hover:bg-gray-100 relative ' onMouseOver={() => setShowAdd(true)} onMouseOut={() => setShowAdd(false)}>
            <table>
                <tr>
                    <td><EditText
                        defaultValue={data.language}
                        inputClassName='text-md '
                        className='text-md text-gray-600 font-semibold'
                    /></td>
                </tr>
                <tr>
                    <td><EditText
                        defaultValue={data.level}
                        inputClassName='text-md '
                        className='text-md text-gray-600 italic'
                    /></td>
                </tr>
            </table>

            <div className={`flex justify-end absolute right-0 top-2 ${showAdd ? `visible` : 'invisible'}`} >
                <div className={' hover:cursor-pointer px-5'} onClick={addNewLanguages}><Icon name="add" size="3xl" /></div>
                <div className={' hover:cursor-pointer px-5'} onClick={() => { removeNewLanguages(index) }}><Icon name="delete" size="3xl" /></div>
            </div>
        </div>
    )
}
const WorkExperienceEntity = ({ index, data, addNewExperience, removeExperience }) => {
    const [showAdd, setShowAdd] = useState(false)
    const [roles, setRoles] = useState(data.roles)
    const addRoles = (data, value) => {

        if (data.value !== "") {

            setRoles([...roles, value])

        } else {
            let d = [...roles]
            d.splice(d.length - 1, 1)
            setRoles(v => d)

        }
    }
    return (
        <div className='p-5 hover:bg-gray-100 relative '
            style={{ pageBreakAfter: 'auto' }}
            onMouseOver={() => setShowAdd(true)} onMouseOut={() => setShowAdd(false)}>
            {/* <p className='text-xl font-semibold'>Sr Software Developer</p> */}
            <EditText
                defaultValue={data.post}
                inputClassName='text-xl font-semibold'
                className='text-xl font-semibold'
            />
            {/* <p className='text-xl'>Lsinextgen</p> */}
            <EditText
                defaultValue={data.company}
                inputClassName='text-xl'
                className='text-xl'
            />
            {/* <p className=''><i>06/2019 - Present</i></p> */}
            <EditText
                defaultValue={data.duration}
                inputClassName=''
                className=''
            />
            {/* <p className='text-gray-600'>This is product based company provides software solutions to diverse community</i></p> */}
            <i> <EditText
                defaultValue={data.title}
                inputClassName='text-gray-600 w-full'
                className='text-gray-600'

            /></i>
            <ul className="px-4 list-disc">
                {
                    roles.map((v, i) => {
                        return <li key={i}><EditText
                            defaultValue={v}
                            inputClassName='text-gray-600 w-full'
                            className='text-gray-600'
                            onSave={(d) => addRoles(d, v)}
                        // onChange={(d) => console.log(d, "h")}

                        /></li>
                    })
                }


            </ul>



            <div className={`flex justify-end absolute right-0 top-2 ${showAdd ? `visible` : 'invisible'}`} >
                <div className={' hover:cursor-pointer px-5'} onClick={addNewExperience}><Icon name="add" size="3xl" /></div>
                <div className={' hover:cursor-pointer px-5'} onClick={() => removeExperience(index)}><Icon name="delete" size="3xl" /></div>
            </div>






        </div>
    );
}
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
Modal.setAppElement('#root');

export default function Content() {
    const defaultExperience = {
        "post": "Your Designation",
        "company": "Company name",
        "duration": "duration",
        "title": "title ",
        "roles": [
            "briefs about responsibility and roles",
        ]
    }
    const defaultQualification = {
        "course": "Bsc Comp Sci",
        "university": "Mumbai University",
        "duration": "06/2010 - 06/2013 "

    }
    const defaultLanguages = {
        "language": "English",
        "level": "Proficient"
    }
    // const defaultLanguages = {
    //     ""

    // }
    // const defaultInterest = {

    // }

    const [preview, setPreview] = useState(null)
    const [src, setSrc] = useState("")
    const [exprience, setExperience] = useState([defaultExperience])
    const [qualification, setQualification] = useState([defaultQualification])
    const [languages, setLanguages] = useState([defaultLanguages])
    const [interest, setInterest] = useState(["chess"])
    const [tags, setTags] = useState(["java", "php", "golang"])
    const handleChange = (tags) => {
        setTags(tags)
    }

    const handleChangeInterest = (interest) => {
        setInterest(interest)
    }
    const addNewExperience = () => {
        setExperience([...exprience, defaultExperience])
    }
    const removeExperience = (index) => {

        if (index != 0) {
            let exp = [...exprience]
            exp.splice(index, 1)
            console.log(exp)
            setExperience(old => exp)
        }

    }
    const addNewLanguages = () => {
        setLanguages([...languages, defaultLanguages])
    }
    const removeNewLanguages = (index) => {

        if (index != 0) {
            let exp = [...languages]
            exp.splice(index, 1)
            console.log(exp)
            setLanguages(old => exp)
        }

    }

    const addNewQualification = () => {
        setQualification([...qualification, defaultQualification])
    }
    const removeNewQualification = (index) => {

        if (index != 0) {
            let exp = [...qualification]
            exp.splice(index, 1)
            console.log(exp)
            setQualification(old => exp)
        }

    }

    const onClose = () => {
        setPreview(null)
    }

    const onCrop = (preview) => {
        setPreview(preview)
    }

    const onBeforeFileLoad = (elem) => {
        if (elem.target.files[0].size > 171680) {
            alert("File is too big!");
            elem.target.value = "";
        };
    }
    // const handleChangeInput = (tag) => {
    //     setTags({ tag })
    // }
    let subtitle;

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    const componentRef = useRef();

    return (
        <section className="relative py-16 bg-gray-100">

            <div className="container max-w-7xl px-4 mx-auto">
                <ReactToPrint
                    trigger={() => <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>Print this out!</button>}
                    content={() => componentRef.current}
                />
                <div ref={componentRef} >

                    <div id='printContainer' className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded-2xl ">
                        <div className="px-4 ">
                            <div className="flex  ">
                                <div className="  px-8   py-8">
                                    <div className="relative">
                                        <div className="w-40 ">

                                            <Image
                                                src={preview == null ? ProfilePicture : preview}
                                                alt="Profile picture"
                                                raised
                                                rounded
                                                onClick={openModal}
                                            />
                                            <Modal
                                                isOpen={modalIsOpen}
                                                onAfterOpen={afterOpenModal}
                                                onRequestClose={closeModal}
                                                style={customStyles}
                                                contentLabel="Example Modal"
                                            >
                                                <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
                                                <button onClick={closeModal}>close</button>
                                                {/* <div>I am a modal</div> */}
                                                <Avatar
                                                    width={390}
                                                    height={295}
                                                    onCrop={onCrop}
                                                    onClose={onClose}
                                                    onBeforeFileLoad={onBeforeFileLoad}

                                                    src={src}
                                                />
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-left my-8">
                                    <EditText
                                        defaultValue="Your Name"
                                        inputClassName='text-gray-900 text-4xl font-serif font-bold leading-normal mt-0 mb-2'
                                        className='text-gray-900 text-4xl font-serif font-bold leading-normal mt-0 mb-2'
                                    />
                                    {/* <H3 color="gray">Dheeraj Vishwakarma</H3> */}
                                    <div className="mt-0 mb-2 text-gray-700 font-medium flex items-center  gap-2">
                                        <Icon name="place" size="xl" />
                                        <EditText
                                            defaultValue="Short title about you"
                                            inputClassName='w-full'
                                            className=''
                                        />
                                    </div>
                                    <div className="mb-2 text-gray-700  flex items-center  gap-2 ">
                                        <Icon name="work" size="xl" />
                                        {/* Sr. Software Developer (8.6 Years) */}
                                        <EditText
                                            defaultValue="Sr. Software Developer (8.6 Years)"
                                            inputClassName='w-full'
                                            className=''
                                        />
                                    </div>
                                    <div className="mb-2 text-gray-700 flex items-center  gap-2">
                                        <Icon name="account_balance" size="xl" />
                                        {/* Mumbai University 2013 */}
                                        <EditText
                                            defaultValue="Mumbai University 2013"
                                            inputClassName='w-full'
                                            className=''
                                        />
                                    </div>
                                </div>
                                {/* <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:self-center flex justify-center mt-10 lg:justify-end lg:mt-0">
                                <Button color="lightBlue" ripple="light">
                                    Conntect
                                </Button>
                            </div> */}
                                {/* <div className="w-full lg:w-4/12 px-4 lg:order-1">
                                <div className="flex justify-center py-4 lg:pt-4 pt-8">
                                    <div className="mr-4 p-3 text-center">
                                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-900">
                                            22
                                        </span>
                                        <span className="text-sm text-gray-700">
                                            Friends
                                        </span>
                                    </div>
                                    <div className="mr-4 p-3 text-center">
                                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-900">
                                            10
                                        </span>
                                        <span className="text-sm text-gray-700">
                                            Photos
                                        </span>
                                    </div>
                                    <div className="lg:mr-4 p-3 text-center">
                                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-900">
                                            89
                                        </span>
                                        <span className="text-sm text-gray-700">
                                            Comments
                                        </span>
                                    </div>
                                </div>
                            </div> */}
                            </div>

                            <div className='p-4 bg-gray-200 text-center'>
                                <div className='m-auto flex items-center gap-4'>
                                    {/* <span className='px-4'>dheerajv4855@gmail.com</span> */}
                                    <div className=" text-gray-700 font-medium flex items-center  gap-2">
                                        <Icon name="email" size="xl" />
                                        <EditText
                                            defaultValue="your-email@example.com"
                                            inputClassName='w-full'
                                            className=''
                                        />
                                    </div>
                                    <div className=" text-gray-700 font-medium flex items-center  gap-2">
                                        <Icon name="phone" size="xl" />
                                        <EditText
                                            defaultValue="9999999999 / 8888888888"
                                            inputClassName='w-full'
                                            className=''
                                        />
                                    </div>
                                    <div className=" text-gray-700 font-medium flex items-center  gap-2">
                                        <Icon name="cake" size="xl" />
                                        <EditText
                                            defaultValue="4-June-2013"
                                            inputClassName='w-full'
                                            className=''
                                        />
                                    </div>
                                    {/* <span className='px-4'>9594776268 / 9324728646</span>
                                <span className='px-4'>Mumbai, India</span>
                                <span className='px-4'>04, June 1993</span> */}
                                </div>
                            </div>

                            <div className="mb-10 border-t border-gray-200 ">
                                <div className='grid grid-cols-5 gap-2 px-4'>
                                    <div className='col-span-3'>
                                        <div className='text-left  py-4 ' >
                                            <h4 className='text-2xl font-bold text-gray-600 uppercase' >Work Experience</h4>
                                            {
                                                exprience.map((v, i) => {
                                                    return <WorkExperienceEntity index={i} removeExperience={removeExperience} key={i} data={v} addNewExperience={addNewExperience} />
                                                })
                                            }


                                            {/* <div className='p-5 hover:bg-gray-100'>
                                            <p className='text-xl font-semibold'>Sr Software Developer</p>
                                            <p className='text-xl'>Lsinextgen</p>
                                            <p className=''><i>06/2019 - Present</i></p>
                                            <p className='text-gray-600'><i>This is product based company provides software solutions to diverse community</i></p>
                                            <ul className="px-4 list-disc">
                                                <li>Working on new projects from scratch individually with php and laravel</li>
                                                <li>Optimized geo location based search engine with around 7m records
                                                    using meilisearch</li>
                                                <li>created api in golang for task management project</li>
                                                <li>created a multi tenant api for inventory + billlings software in golang</li>
                                            </ul>

                                        </div>
                                        <div className='p-5 hover:bg-gray-100'>
                                            <p className='text-xl font-semibold'>Sr Software Developer</p>
                                            <p className='text-xl'>Lsinextgen</p>
                                            <p className=''><i>06/2019 - Present</i></p>
                                            <p className='text-gray-600'><i>This is product based company provides software solutions to diverse community</i></p>
                                            <ul className="px-4 list-disc">
                                                <li>Working on new projects from scratch individually with php and laravel</li>
                                                <li>Optimized geo location based search engine with around 7m records
                                                    using meilisearch</li>
                                                <li>created api in golang for task management project</li>
                                                <li>created a multi tenant api for inventory + billlings software in golang</li>
                                            </ul>

                                        </div> */}
                                        </div>
                                        <div className='text-left  py-4'>
                                            <h4 className='text-2xl font-bold text-gray-600 uppercase' color="gray">Qualification</h4>
                                            <div>
                                                {
                                                    qualification.map((v, i) => {
                                                        return <Education key={i} index={i} data={v} addNewQualification={addNewQualification} removeNewQualification={removeNewQualification} />
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='text-left  py-4'>
                                            <h4 className='text-2xl font-bold text-gray-600 uppercase' color="gray">Skills</h4>
                                            <div>
                                                <TagsInput value={tags} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className='text-left  py-4'>
                                            <h4 className='text-2xl font-bold text-gray-600 uppercase' color="gray">Languages</h4>
                                            <div>
                                                {
                                                    languages.map((v, i) => {
                                                        return <Languages key={i} index={i} data={v} addNewLanguages={addNewLanguages} removeNewLanguages={removeNewLanguages} />
                                                    })
                                                }


                                            </div>
                                        </div >
                                        <div className='text-left  py-4'>
                                            <h4 className='text-2xl font-bold text-gray-600 uppercase ' color="gray">Interest</h4>
                                            <div>
                                                <TagsInput value={interest} onChange={handleChangeInterest} className="interest" />

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}
